// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-works-index-js": () => import("./../../../src/pages/works/index.js" /* webpackChunkName: "component---src-pages-works-index-js" */),
  "component---src-pages-works-project-js": () => import("./../../../src/pages/works/project.js" /* webpackChunkName: "component---src-pages-works-project-js" */),
  "component---src-pages-works-rasterhang-js": () => import("./../../../src/pages/works/rasterhang.js" /* webpackChunkName: "component---src-pages-works-rasterhang-js" */),
  "component---src-pages-works-vectorhang-js": () => import("./../../../src/pages/works/vectorhang.js" /* webpackChunkName: "component---src-pages-works-vectorhang-js" */)
}

