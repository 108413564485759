import { createCamera } from './components/camera.js';
import { createMeshGroup, getOpacity } from './components/meshGroup.js';
import { createScene } from './components/scene.js';
import { createRenderer } from './systems/renderer.js';
import { createControls } from './systems/controls.js';
import { update } from './systems/update.js';
import { Clock } from 'three';
import { gsap } from "gsap";

const clock = new Clock();
const updatables = [];

let camera;
let renderer;
let scene;
let meshGroup;
let controls;
let tl;
let shaderOpacity;

class World {
    constructor() {
        camera = createCamera();
        scene = createScene();
        renderer = createRenderer();
        meshGroup = createMeshGroup();
        shaderOpacity = getOpacity();

        controls = createControls(camera, renderer.domElement);

        updatables.push(controls, meshGroup);

        scene.add(meshGroup);

        this.canvas = renderer.domElement;

        tl = gsap.timeline();
    }

    render(delta) { 
        renderer.render(scene, camera);
    }

    setSize(width, height, pixelRatio) {
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderer.setSize(width, height);
        renderer.setPixelRatio(pixelRatio);
    }

    setPreset(preset) {
        switch (preset) {
            case 0: 
                tl.clear();
                tl.to(camera.position, {x: 0, y: 0, z: 10, duration: 1, overwrite: true})
                  .to(scene.rotation, {z: 0, y: 0, x: 0, duration: 1, overwrite: true }, '-=1')
                  .to(scene.scale, {x: 1, y: 1, z: 1, duration: 1, overwrite: true }, '-=1')
                  .to(shaderOpacity, {value: 1, duration: 1, overwrite: true}, '-=1');
                break;
            case 1: 
                tl.clear();
                tl.to(camera.position, {x: 5, y: -5, z: 7, duration: 1, overwrite: true})
                  .to(scene.rotation, {z: 0, y: 0, x: 0, duration: 1, overwrite: true }, '-=1')
                  .to(scene.scale, {x: 1, y: 1, z: 1, duration: 1, overwrite: true }, '-=1')
                  .to(shaderOpacity, {value: 1, duration: 1, overwrite: true}, '-=1');
                break;
            case 2: 
                tl.clear();
                tl.to(camera.position, {x: 0, y: 0, z: 10, duration: 1, overwrite: true})
                  .to(scene.rotation, {z: 0, y: 0, x: 0, duration: 1, overwrite: true }, '-=1')
                  .to(scene.scale, {x: 0.6, y: 0.6, z: 0.6, duration: 1, overwrite: true }, '-=1')
                  .to(shaderOpacity, {value: 1, duration: 1, overwrite: true}, '-=1');
                break;
            case 3: 
                tl.clear();
                tl.to(camera.position, {x:0, y: 0, z: 10, duration: 1, overwrite: true})
                  .to(scene.rotation, {z: -1, y: 0, x: 0, duration: 1, overwrite: true }, '-=1')
                  .to(scene.scale, {x: 0.5, y: 0.5, z: 0.5, duration: 1, overwrite: true }, '-=1')
                  .to(shaderOpacity, {value: 1, duration: 1, overwrite: true}, '-=1');
                break;
            case 4:
                tl.clear();
                tl.to(shaderOpacity, {value: 0, duration: 1, overwrite: true});
                break;              
            default:
                break;
        }
    }

    start() {
        renderer.setAnimationLoop(() => {
            const delta = clock.getDelta();
            update(updatables, delta);
            this.render(delta);
        })
    }
}

export { World };
