import React, { useRef, useEffect } from 'react';
import Background from './Background';
import Navbar from './Navbar';

import "@fontsource/roboto-condensed/300.css";

import './index.css';
import { gsap } from 'gsap';

export default ({ children }) => {
    const loading = useRef(null);

    useEffect(() => {
        document.fonts.load("12px Roboto Condensed")
        .then(() => gsap.to(loading.current, { opacity: 0, duration: 1, onComplete: () => { loading.current.style.zIndex = -99999 }}));
    }, []);

    return(
        <>
            <div id="loading" ref={loading}></div>
            <div id="content">
                <Background />
                <Navbar />
                <div id="falloff"></div>
                {children}
            </div>
        </>
    )
}