import React, { useEffect, useRef } from 'react';
import threeEntryPoint from '../three/src/main';
import './Background.css';

function Background(props) {
    const threeRootElement = useRef(null);

    useEffect(() => {
        threeEntryPoint(threeRootElement.current);
    }, []);

    return (
        <>
            <div id="overlay"></div>
            <div id="scene-container" ref={threeRootElement}></div>
        </>
    );
}

export default Background;
