import { World } from './World/World.js';

let myWorld;

const containerElement = (el) => {
    const world = new World();

    myWorld = world;

    const container = el;
    container.append(world.canvas);

    world.setSize(
        container.clientWidth,
        container.clientHeight,
        window.devicePixelRatio
    );

    window.addEventListener('resize', () => {
		world.setSize(
			container.clientWidth,
			container.clientHeight,
			window.devicePixelRatio,
		);
    });

    world.start();
}

const setPreset = (preset) => {
	myWorld.setPreset(preset);
};

export { containerElement as default, setPreset };