import {
    PlaneBufferGeometry,
    Group,
    Mesh,
    ShaderMaterial,
    Vector3
} from 'three';

import { vertexShader, fragmentShader } from './shaders';

const uniforms = {
    iTime: { value: 0 },
    iResolution:  { value: new Vector3(1, 1, 1) },
    iOpacity : { value: 1 }
};

function createMeshGroup() {
    const group = new Group();
    const geometry = new PlaneBufferGeometry(1, 1, 1);
    const material = new ShaderMaterial( {
        uniforms: uniforms,
        fragmentShader: fragmentShader,
        vertexShader: vertexShader
    } );
    material.transparent = true;

    const protoMesh = new Mesh(geometry, material);
    group.add(protoMesh);

    for (let i=0; i<1; i+=0.01) {
        const mesh = protoMesh.clone();

        mesh.position.x = (Math.random() * 20) - 10;
        mesh.position.y = (Math.random() * 10) - 5;
        mesh.position.z = -i * 10;
        mesh.rotation.z = Math.random() * 3.14;
        mesh.scale.x = Math.random() * 4.0 +0.3;
        mesh.scale.y = Math.random() * 2.0 +0.3;

        group.add(mesh);
    }

    let time = 0.0;

    group.tick = (delta) => {
        time += 0.75 * delta;
        uniforms.iTime.value = time;
    };

    return group;
}

function getOpacity () {
    return uniforms.iOpacity;
}

export { createMeshGroup, getOpacity };
