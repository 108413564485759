const vertexShader = `
    varying vec2 vUv;

    void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
`;

const fragmentShader = `
// GLOWING FRAME © 2021 Luka Batista
// 2D box signed distance function by Inigo Quilez

#include <common>

uniform vec3 iResolution;
uniform float iTime;
uniform float iOpacity;

float sdBox( in vec2 p, in vec2 b)
{
    vec2 d = abs(p) - b;
    return length(max(d,0.0)) + min(max(d.x,d.y),0.0);
}

float sdBoxOnion( in vec2 p, in vec2 b, in float r )
{
    return abs(sdBox(p, b)) - r;
}

vec4 glowingFrame(  in vec2 uv, 
                    in float time,
                    in float timeOffset,
                    in float lineThickness,
                    in float glowIntensity,
                    in vec3 glowTint,
                    in float beamAngle  )
{
    // square frame
    float frame = 1.0 - smoothstep(0., lineThickness, sdBoxOnion(uv, vec2(0.9), 0.0));
    
    // beam
    vec2 unit = vec2(cos(time + timeOffset), sin(time + timeOffset));
    float dotp = dot( unit, uv );
    float mags = length(unit) * length(uv);
    float angle = acos(dotp / mags);
    float beam = smoothstep(beamAngle, PI, angle);
    
    return vec4( frame * beam * vec4(glowTint, 1.0) * glowIntensity);
}

void mainImage(out vec4 fragColor, in vec2 fragCoord)
{
    vec2 uv = (-iResolution.xy + 2.0*fragCoord)/iResolution.y;
    vec3 col = vec3(0.);

    float l1_timeOffset = 0.;
    float l1_lineThickness = 0.1;
    float l1_glowIntensity = 0.63;
     vec3 l1_glowTint = vec3(0.4, 0.1, 0.5);
    float l1_beamAngle = 2.6;
    vec4 layer1 = glowingFrame( uv, iTime, l1_timeOffset, l1_lineThickness, l1_glowIntensity, l1_glowTint, l1_beamAngle );
    
    float l2_timeOffset = 0.3;
    float l2_lineThickness = 0.05;
    float l2_glowIntensity = 0.9;
     vec3 l2_glowTint = vec3(0.7, 0.6, 0.8);
    float l2_beamAngle = 2.9;
    vec4 layer2 = glowingFrame( uv, iTime, l2_timeOffset, l2_lineThickness, l2_glowIntensity, l2_glowTint, l2_beamAngle );
   
    float l3_timeOffset = -0.9;
    float l3_lineThickness = 0.02;
    float l3_glowIntensity = 0.56;
     vec3 l3_glowTint = vec3(0.2, 0.3, 0.9);
    float l3_beamAngle = 2.4;
    vec4 layer3 = glowingFrame( uv, iTime, l3_timeOffset, l3_lineThickness, l3_glowIntensity, l3_glowTint, l3_beamAngle );
    
    fragColor = iOpacity * vec4( layer1 + layer2 + layer3 );
}

varying vec2 vUv;

void main() {
    mainImage(gl_FragColor, vUv * iResolution.xy);
}
`;

export { vertexShader, fragmentShader };