import React, {useEffect, useRef, useState} from 'react';
import TransitionLink from "gatsby-plugin-transition-link";
import { gsap } from 'gsap';
import * as navbarStyles from './Navbar.module.css';

import "@fontsource/roboto-condensed/400.css";

const Navbar = () => {
    const tl = gsap.timeline();
    const links = useRef(null);
    const line1 = useRef(null);
    const line2 = useRef(null);
    const line3 = useRef(null);

    const [innerWidth, setInnerWidth] = useState(0);
    const [responsiveFlag, setResponsiveFlag] = useState(false);
    const [backToResponsiveFlag, setBackToResponsiveFlag] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setInnerWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
    });

    useEffect(() => {
        if ((innerWidth > 768) && responsiveFlag) {
            gsap.to(links.current, { zIndex: 4000, opacity: 1, duration: 0 });

            setResponsiveFlag(false);
            setBackToResponsiveFlag(true);
        }
        if ((innerWidth <= 768) && backToResponsiveFlag) {
            gsap.to(links.current, { zIndex: -9999, opacity: 0, duration: 0 });

            setBackToResponsiveFlag(false);
        }
    }, [innerWidth, responsiveFlag]);

    const [mobileMenu, setMobileMenu] = useState(false);

    const toggleMobileMenu = () => {
        if (!mobileMenu) {
            openMobileMenu();
        } else {
            closeMobileMenu();
        }
    };
    
    const openMobileMenu = () => {
        gsap.to(links.current, { zIndex: 9999, duration: 0 });
        gsap.to(links.current, { opacity: 1, duration: 0.5 });
        gsap.to(line1.current, { rotate: -45, x: -7, y: 7, duration: 0.2 });
        gsap.to(line3.current, { rotate: 45, x: -7, y: -7, duration: 0.2 });
        gsap.to(line2.current, { opacity: 0, duration: 0.2 });

        setMobileMenu(true);
    };

    const closeMobileMenu = () => {
        if(window.innerWidth <= 768) {
            tl.to(links.current, { opacity: 0, duration: 0.5 })
              .to(links.current, { zIndex: -9999, duration: 0 });
            gsap.to(line1.current, { rotate: 0, x: 0, y: 0, duration: 0.2 });
            gsap.to(line3.current, { rotate: 0, x: 0, y: 0, duration: 0.2 });
            gsap.to(line2.current, { opacity: 1, duration: 0.2 });

            setMobileMenu(false);
            setResponsiveFlag(true);
        }
    };

    return (
        <nav className={navbarStyles.navbar}>
            <div className={navbarStyles.title}>
                <TransitionLink
                    onClick={closeMobileMenu}
                    to="/"
                    exit={{
                        length: 1,
                    }}
                    entry={{
                    }}
                >
                    <span className={navbarStyles.first}>LUKA</span> <span className={navbarStyles.first}>BATISTA</span>
                </TransitionLink> 
            </div>
            <ul className={navbarStyles.links} ref={links}>
                <li>
                    <TransitionLink
                        onClick={closeMobileMenu}
                        to="/about"
                        exit={{
                            length: 1,
                        }}
                        entry={{
                        }}
                    >
                        <span className={navbarStyles.first}>ABOUT</span>
                    </TransitionLink>
                </li>
                <li>
                    <TransitionLink
                        onClick={closeMobileMenu}
                        to="/works"
                        exit={{
                            length: 1,
                        }}
                        entry={{
                        }}
                    >
                        <span className={navbarStyles.first}>WORKS</span>
                    </TransitionLink>
                </li>
                <li>
                    <TransitionLink
                        onClick={closeMobileMenu}
                        to="/contact"
                        exit={{
                            length: 1,
                        }}
                        entry={{
                        }}
                    >
                        <span className={navbarStyles.first}>CONTACT</span>
                    </TransitionLink>
                </li>
            </ul>
            <div className={navbarStyles.burger} onClick={toggleMobileMenu} role="button">
                <div className={navbarStyles.line1} ref={line1}></div>
                <div className={navbarStyles.line2} ref={line2}></div>
                <div className={navbarStyles.line3} ref={line3}></div>
            </div>
        </nav>
    );
}

export default Navbar;
